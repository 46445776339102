<template>
  <div class="lms-users">
    <search />
  </div>
</template>

<script>
import Search from '../../shared/Search.vue'
export default {
  components: {
    Search
  }
}
</script>
