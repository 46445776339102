<template>
  <div>
    <top-nav />
    <lms-users-index v-if="this.$route.name == 'admin-lms-users'" />
    <lms-user-edit v-if="this.$route.name == 'admin-lms-user'" />

</div>
</template>

<script>
import TopNav from './TopNav.vue'
import LmsUsersIndex from './lms-users/Index.vue'
import LmsUserEdit from './lms-users/Edit.vue'

export default {
  components: {
    TopNav,
    LmsUsersIndex,
    LmsUserEdit
  }
}
</script>
