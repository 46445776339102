<template>
  <nav-container>
    <template v-slot:headerName>
      <div class="title">
        Admin
      </div>
    </template>
    <template v-slot:listItems>
      <ul class="menu">
        <li
          :class="assetClasses('admin-lms-users')"
          class="nav-button admin-lms-users"
          v-if="currentUser.permissions.can_write_admin_users"
        >
          <router-link :to="{ name: 'admin-lms-users' }">LMS Users</router-link>
        </li>
      </ul>
    </template>
  </nav-container>
</template>

<script>
import { mapGetters } from 'vuex'
import NavContainer from '../shared/NavContainer.vue'
export default {
  components: {
    NavContainer
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
  methods: {
    assetClasses (type) {
      let active = false
      if (type == 'admin-lms-users') {
        active = this.$router.isRoute('admin-lms-user')
      }
      return {
        'nav-item': true,
        'top-active': active
      }
    }
  }
}
</script>
