<template>
  <div>
    <div class="lms-user-container" v-if="asset.id">
      <div class="lms-user">
        <div class="left">
          <div class="flex-base-row profile-photo">
            <user-svg v-if="!asset.profile_photo && !asset.profile_photo_url" />
            <img :class="['profile-photo-preview', { 'hidden': !asset.profile_photo }]" ref="profile-photo-preview" src="#" />
            <img :class="['profile-photo-preview', { 'hidden': asset.profile_photo }]" ref="profile-photo-preview" v-if="asset.profile_photo_url && !asset.profile_photo" :src="asset.profile_photo_url" />

            <label for="profile_photo" class="upload-file-button hlt-button secondary">
                Change
            </label>

            <input
              id="profile_photo"
              ref="profile_photo"
              type="file"
              class="upload-file-field"
              name="profile_photo"
              accept="image/*"
              @change="handleFileUpload('profile_photo')"
            >

          </div>

          <div class="flex-base-row user-type">
            <div class="flex-left">
              <label>User Type</label>
              <hlt-toggle
                class="is-admin-button"
                :value="asset.is_admin"
                style-type="medium"
                :font-size="11"
                :labels="{
                  checked: 'Admin',
                  unchecked: 'Normal'
                }"
                sync
                @change="toggleIsAdmin"
              />
            </div>
          </div>
          <div class="flex-base-row">
            <button class="hlt-button secondary" @click="resetPassword">Reset Password</button>
          </div>
        </div>

        <div class="right">
          <div class="flex-base-row">
            <div class="flex-left">
              <label>First Name</label>
              <input
                v-model="asset.first_name"
                :class="['hlt-input', {'error': errors['first_name'] }]"
              />
              <span
                v-if="!errors['first_name']"
                class="helper-text required-title-text"
              >Required</span>

              <error-message
                v-if="errors['first_name']"
                :message="errors['first_name'][0]"
              />

            </div>
            <div class="flex-right">
              <label>Last Name</label>
              <input
                v-model="asset.last_name"
                :class="['hlt-input', {'error': errors['last_name'] }]"
              />
              <span
                v-if="!errors['last_name']"
                class="helper-text required-title-text"
              >Required</span>

              <error-message
                v-if="errors['last_name']"
                :message="errors['last_name'][0]"
              />
            </div>
          </div>

          <div class="flex-base-row">
            <div class="flex-left">
              <label>Email</label>
              <input
                v-model="asset.email"
                :class="['hlt-input', {'error': errors['email'] }]"
              />
              <span
                v-if="!errors['email']"
                class="helper-text required-title-text"
              >Required</span>
              <error-message
                v-if="errors['email']"
                :message="errors['email'][0]"
              />
            </div>
            <div class="flex-right">
              <label>Phone</label>
              <input v-model="asset.phone" class="hlt-input" />
            </div>
          </div>

          <div class="flex-base-row">
            <div class="flex-left">
              <label>Hourly rate</label>
              <input v-model="asset.hourly_rate" class="hlt-input" />
            </div>
            <div class="flex-right">
            </div>
          </div>

          <div class="flex-base-row">
            <div class="flex-left">
              <label>Apps</label>
              <hlt-multiselect
                ref="individual-app-ids"
                v-model="selectedApp"
                track-by="id"
                label="internal_name"
                class=""
                :options="currentUser.apps"
                :multiple="false"
                :searchable="true"
                :show-no-results="true"
                :allow-empty="true"
                :clear-on-select="false"
                :show-labels="false"
                placeholder="Add App"
              />
              <div class="tags-container">
                <div
                  v-for="(app, i) in activeApps"
                  :key="i"
                  class="tag-button"
                  :app="app"
                >
                  <span
                    class="app-name tag-content"
                  >{{ appName(app) }}</span>
                  <div class="x-button" @click="removeApp(app)">
                    x
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-base-row">
            <div class="flex-left">
              <label>Roles</label>
              <hlt-multiselect
                ref="individual-app-ids"
                v-model="selectedRole"
                track-by="id"
                label="name"
                class=""
                :options="allRoles"
                :multiple="false"
                :searchable="true"
                :show-no-results="true"
                :allow-empty="true"
                :clear-on-select="false"
                :show-labels="false"
                placeholder="Add Role"
              />
              <div class="tags-container">
                <div
                  v-for="(role, i) in activeRoles"
                  :key="i"
                  class="tag-button"
                  :role="role"
                >
                  <span
                    class="role-name tag-content"
                  >{{ roleName(role) }}</span>
                  <div class="x-button" @click="removeRole(role)">
                    x
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-base-row">
            <div class="flex-left">
              <label>Verticals <span
                class="helper-text"
                > ( grants access to all apps in the vertical )</span></label>
              <hlt-multiselect
                ref="individual-app-ids"
                v-model="selectedVertical"
                track-by="id"
                label="name"
                class=""
                :options="verticals"
                :multiple="false"
                :searchable="true"
                :show-no-results="true"
                :allow-empty="true"
                :clear-on-select="false"
                :show-labels="false"
                placeholder="Add Vertical"
              />
              <div class="tags-container">
                <div
                  v-for="(vertical, i) in activeVerticals"
                  :key="i"
                  class="tag-button"
                  :vertical="vertical"
                >
                  <span
                    class="vertical-name tag-content"
                  >{{ verticalName(vertical) }}</span>
                  <div class="x-button" @click="removeVertical(vertical)">
                    x
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <edit-footer>
      <template v-slot:left>
        <div class="left">

        </div>
      </template>
      <template v-slot:center>
        <div class="center">
          <div>
            <button
              v-if="!saving"
              class="hlt-button primary save-button"
              @click="save()"
            >
              Save Changes
            </button>
            <button
              v-else
              class="hlt-button primary disabled save-button"
            >
              <spinner /> Save Changes
            </button>
          </div>
        </div>
      </template>
    </edit-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import railsRoutes from '@/store/rails-routes'
import HltToggle from '../../assets/shared/page_components/Toggle.vue'
import HLTMultiselect from '../../shared/VueMultiSelect.vue'
import EditFooter from '../../assets/shared/page_components/EditFooter.vue'
import UserSvg from '@/assets/images/icons/user-solid.svg'
import Spinner from '../../assets/shared/Spinner.vue'

export default {
  components: {
    HltToggle,
    'hlt-multiselect': HLTMultiselect,
    EditFooter,
    UserSvg,
    Spinner
  },
  data () {
    return {
      saving: false,
      apps: [],
      app: null,
      currentRoles: [],
      role: null,
      currentVerticals: [],
      vertical: null
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'currentUser',
      'verticals',
      'roles',
      'canWriteToAsset',
      'errors'
    ]),
    allRoles () {
      if(this.roles) {
        return this.roles
      } else {
        return []
      }
    },
    activeApps () {
      return this.apps.filter(x => !x._destroy)
    },
    activeRoles () {
      return this.currentRoles.filter(x => !x._destroy)
    },
    activeVerticals () {
      return this.currentVerticals.filter(x => !x._destroy)
    },
    selectedApp: {
      get () {
        return this.app
      },
      set (optionValue) {
        this.app = null
        
        if(this.apps.filter(x => x.app_id == optionValue.id).length == 0) {
          this.apps.push({ app_id: optionValue.id })
        }
      }
    },
    selectedRole: {
      get () {
        return this.role
      },
      set (optionValue) {
        this.role = null
        
        if(this.currentRoles.filter(x => x.role_id == optionValue.id).length == 0) {
          this.currentRoles.push({ role_id: optionValue.id })
        }
      }
    },
    selectedVertical: {
      get () {
        return this.vertical
      },
      set (optionValue) {
        this.vertical = null
        
        if(this.currentVerticals.filter(x => x.vertical_id == optionValue.id).length == 0) {
          this.currentVerticals.push({ vertical_id: optionValue.id })
        }
      }
    },
  },
  methods: {
    ...mapActions([
      'apiAdminUserById',
      'edit',
      'getVerticals',
      'getRoles',
      'attachmentUpdate'
    ]),
    ...mapMutations([
      'setAssetType',
      'setErrors',
      'resetErrors'
    ]),
    resetPassword () {
      axios.put(railsRoutes.api_path({ type: 'admin_users', action: 'send_password_reset_instructions' }), { email: this.asset.email }).then(() => {
        this.$notify({ title: 'Password reset instructions sent', type: 'success' })
      })
    },
    handleFileUpload (name) {
      this.$set(this.asset, name, this.$refs[name].files[0])
      this.$refs['profile-photo-preview'].src = URL.createObjectURL(this.$refs[name].files[0])
    },
    appName (app) {
      return this.currentUser.apps.filter(x => x.id == app.app_id)[0].internal_name
    },
    roleName (role) {
      if(this.roles) {
        return this.roles.filter(x => x.id == role.role_id)[0].name
      } else {
        return '--'
      }
    },
    verticalName (vertical) {
      if(this.verticals.length > 0) {
        return this.verticals.filter(x => x.id == vertical.vertical_id)[0].name
      } else {
        return "--"
      }
    },
    toggleIsAdmin () {
      this.asset.is_admin = !this.asset.is_admin
    },
    removeApp (app) {
      let old = this.apps.filter(x => x.app_id == app.app_id)[0]
      old._destroy = true
      let index = this.apps.indexOf(app)
      this.apps[index] = old
      this.$set(this.apps, index, old)
    },
    removeRole (role) {
      let old = this.currentRoles.filter(x => x.role_id == role.role_id)[0]
      old._destroy = true
      let index = this.currentRoles.indexOf(role)
      this.currentRoles[index] = old
      this.$set(this.currentRoles, index, old)
    },
    removeVertical (vertical) {
      let old = this.currentVerticals.filter(x => x.vertical_id == vertical.vertical_id)[0]
      old._destroy = true
      let index = this.currentVerticals.indexOf(vertical)
      this.currentVerticals[index] = old
      this.$set(this.currentVerticals, index, old)
    },
    save () {
      if (this.canWriteToAsset && !this.saving) {
        this.saving = true
        if (this.asset.id) {
          this.asset.admin_user_apps = this.apps
          this.asset.admin_user_roles = this.currentRoles
          this.asset.admin_user_verticals = this.currentVerticals
          this.attachmentUpdate({ fullUrl: railsRoutes.api_path({ type: 'admin_users', action: 'update_user', id: this.asset.id }), asset: this.asset }).then(response => {
            this.edit(response.data.admin_user)
            this.resetErrors()
            this.saving = false
            this.$notify({ title: 'User Saved', type: 'success' })
          }).catch(error => {
            this.setErrors({ errors: error.response.data })
            console.error('Error: ', error.response.data)
            this.saving = false
            this.$notify({ title: 'Error saving user', type: 'error' })
          })
        } else {
          // This should never happen
          this.saving = false
          this.$notify({ title: 'Error saving user', type: 'error' })
        }
      }
    },
  },
 async mounted () {
    this.setAssetType({ assetType: 'admin_user' })
    this.apiAdminUserById({ id: this.$route.params.id }).then(response => {
      let record = response.data.admin_user
      this.edit(record)
      this.apps = this.asset.admin_user_apps
      this.currentRoles = this.asset.admin_user_roles
      this.currentVerticals = this.asset.admin_user_verticals
    })
    await this.getVerticals()
    await this.getRoles()
  }
}
</script>

<style lang="scss" scoped>
.lms-user-container {
  display: flex;
  justify-content: center;  
  min-height: 100vh;
  
  .lms-user {
    width: 60%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 200px;
      min-width: 200px;
      margin-right: 50px;
      .profile-photo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0px;

        .hidden {
          display: none;
        }
        .profile-photo-preview {
          max-width: 150px;
        }
        .upload-file-button {
          // border: 1px solid $space-gray-10;
          // padding: 5px 12px;
          // border-radius: 4px;
          margin-top: 10px;
          font-size: 12px;
          // cursor: pointer;
        }
        .upload-file-field {
          display: none;
        }
        svg {
          border: 1px solid $space-gray;
          padding: 10px;
          width: 100px;
          color: $space-gray;
          fill: $space-gray;
        }
      }
      .user-type {
        margin-bottom: 40px;
      }
    }
    .right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .helper-text {
      color: $space-black-50;
      font-size: 12px;
    }

    .flex-base-row {
      margin: 0px 0px 10px 0px;
      height: unset;
      input {
        margin-right: 30px;
        padding: 5px;
      }
    }

    .tags-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0 10px;
    }
    .tag-button {
      color: darken($grey-1, 20%);
      font-size: 12px;
      margin-right: 10px;
      margin-bottom: 5px;
      margin-top: 10px;
      display: flex;
      align-items: stretch;
    }
    .tag-content {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: lighten($sky-blue, 30%);
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      align-items: center;
    }

    .x-button {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      font-size: 16px;
      margin-right: 0px;
      padding: 3px;
      width: 22px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: $space-gray-10;
      background: $space-gray-30;
      cursor: pointer;
    }
    .x-button:hover {
      color: $slate-gray;
    }
  }
}
</style>

<style lang="scss">
.lms-user-container {
  .multiselect {
    border: 1px solid $space-gray;
  }
  .multiselect__tags {
    min-height: 40px; 
    height: unset;
    padding: 0px 0px 0px 10px;
    align-items: center;
  }
  .multiselect__tags-wrap {
    flex-wrap: wrap !important;
  }
  .multiselect__input {
    margin: 0px;
    padding: 0px 0px 0px 10px;
  }
}
</style>